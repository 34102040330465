import React, { useState, useMemo } from 'react';
import {
  Divider,
  IconButton,
  List,
  SwipeableDrawer,
  useMediaQuery,
} from '@material-ui/core';
import {
  List as ListEvents,
  Inbox,
  Calendar,
  CheckSquare,
  LogOut,
  User,
  HelpCircle,
  Gift,
  ThumbsUp,
  Video,
  Mail,
  PhoneCall,
  ShoppingCart,
} from 'react-feather';
import { useTheme } from '@material-ui/styles';
import { useNavigate } from 'react-router-dom';
import { usePublicConfig } from '@pv/common/providers';
import MenuIcon from '@material-ui/icons/Menu';
import { apiHost } from '@pv/common/api';
import { HamburgerMenuItem } from '@pv/core/src/components/HamburgerMenuItem';
import { ConfettiIcon } from '../../../icons';
import { useVenues } from '../../../../providers/venues';

const API_HOST = apiHost(process.env.REACT_APP_PV_ENV);

const HamburgerMenu = () => {
  const theme = useTheme();
  const { publicConfig } = usePublicConfig();
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const navigate = useNavigate();
  const { venues } = useVenues();
  const expressBookEnabled = useMemo(
    () => venues?.some((v) => v.expressBookSettings.enabled),
    [venues]
  );
  const [open, setOpen] = useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const navAndClose = (path) => () => {
    navigate(path);
    onClose();
  };

  const openLinkAndCloseDrawer = (path) => () => {
    window.open(path);
    setOpen(false);
  };

  const pagesList = [
    {
      icon: <ConfettiIcon style={{ color: theme.palette.primary.main }} />,
      text: 'Events',
      onClick: navAndClose('/events'),
    },
    {
      icon: <ShoppingCart style={{ color: theme.palette.primary.main }} />,
      text: 'Express Book',
      onClick: navAndClose('/express_book'),
      hidden: !expressBookEnabled,
    },
    {
      icon: <Inbox style={{ color: theme.palette.primary.main }} />,
      text: 'Inbox',
      onClick: navAndClose('/inbox'),
    },
    {
      icon: <Calendar style={{ color: theme.palette.primary.main }} />,
      text: 'Calendar',
      onClick: navAndClose('/calendar'),
    },
    {
      icon: <CheckSquare style={{ color: theme.palette.primary.main }} />,
      text: 'Tasks',
      onClick: navAndClose('/tasks'),
    },
  ];

  const helpList = [
    {
      icon: <Video style={{ color: theme.palette.primary.main }} />,
      text: 'Training Video',
      onClick: openLinkAndCloseDrawer(
        'https://support.perfectvenue.com/articles/913910-training-videos'
      ),
    },
    {
      icon: <ThumbsUp style={{ color: theme.palette.primary.main }} />,
      text: 'Feedback',
      onClick: openLinkAndCloseDrawer(
        'https://perfectvenue.canny.io/feature-requests'
      ),
    },
    {
      icon: <Gift style={{ color: theme.palette.primary.main }} />,
      text: 'Refer a Friend',
      onClick: openLinkAndCloseDrawer('https://form.typeform.com/to/vNu4kAfJ'),
    },
    {
      icon: <HelpCircle style={{ color: theme.palette.primary.main }} />,
      text: 'Help Center',
      onClick: openLinkAndCloseDrawer('https://support.perfectvenue.com/'),
    },
    {
      icon: <Mail style={{ color: theme.palette.primary.main }} />,
      text: 'Email Support',
      onClick: openLinkAndCloseDrawer(
        'https://www.perfectvenue.com/support-request'
      ),
    },
    {
      icon: <PhoneCall style={{ color: theme.palette.primary.main }} />,
      text: 'Call Support',
      onClick: () => alert('Call or Text (415) 906-4190 for assistance'),
    },
  ];

  const settingsList = [
    ...(smallScreen
      ? [
          {
            icon: <User style={{ color: theme.palette.primary.main }} />,
            text: 'Profile',
            onClick: navAndClose('/settings/profile'),
          },
        ]
      : []),
    {
      icon: <LogOut style={{ color: theme.palette.primary.main }} />,
      text: 'Logout',
      onClick: () => {
        window.location.href = `${API_HOST}${publicConfig.paths.destroyUserSession}`;
      },
    },
  ];

  return (
    <>
      <IconButton onClick={onOpen}>
        <MenuIcon style={{ color: 'white' }} />
      </IconButton>
      <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={onClose}
        onOpen={onOpen}
      >
        <div>
          <List component="nav" style={{ width: '250px' }}>
            {pagesList
              .filter((p) => !p.hidden)
              .map(({ icon, text, onClick }) => (
                <HamburgerMenuItem
                  key={text}
                  icon={icon}
                  text={text}
                  onClick={onClick}
                />
              ))}

            <Divider />
            {helpList.map(({ icon, text, onClick }) => (
              <HamburgerMenuItem
                key={text}
                icon={icon}
                text={text}
                onClick={onClick}
              />
            ))}
            <Divider />
            {settingsList.map(({ icon, text, onClick }) => (
              <HamburgerMenuItem
                key={text}
                icon={icon}
                text={text}
                onClick={onClick}
              />
            ))}
          </List>
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default HamburgerMenu;
